import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { ImageListItem } from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/GridListTile';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from "react";
import classes from './perplexity.module.css';
import MessageList from './MessageList'
import PropTypes from 'prop-types'

// ******************
// Code for resizing from https://htmldom.dev/make-a-resizable-element/
// ******************
document.addEventListener('DOMContentLoaded', function() {
  document.getElementById('dragIcon').ondragstart = function () { return false; };

  // Query the element
  const ele = document.getElementById('resizeMe');

  // The current position of mouse
  let x = 0;
  let y = 0;

  // The dimension of the element
  let w = 0;
  let h = 0;

  // ***** Touch events for phones
  // that's triggered when user drags the resizer
  const touchStartHandler = function(e) {
    e.preventDefault()

    // Get the current mouse position
    var touches = e.changedTouches;

    x = touches[0].pageX;
    y = touches[0].pageY;

    // Calculate the dimension of element
    const styles = window.getComputedStyle(ele);
    w = parseInt(styles.width, 10);
    h = parseInt(styles.height, 10);

    // Attach the listeners to `document`
    document.addEventListener('touchmove', touchMoveHandler);
    document.addEventListener('touchend', touchEndHandler);
  };

  const touchMoveHandler = function(e) {
    e.preventDefault()

    // How far the mouse has been moved
    const dx = e.changedTouches[e.changedTouches.length - 1].pageX - x;
    const dy = e.changedTouches[e.changedTouches.length - 1].pageY - y;

    // Adjust the dimension of element
    // ele.style.width = `${w + dx}px`;
    ele.style.height = `${h + dy}px`;
  };

  const touchEndHandler = function() {
    document.removeEventListener('touchmove', touchMoveHandler);
    document.removeEventListener('touchend', touchEndHandler);
  };



  // ***** Mouse events for desktop
  const mouseDownHandler = function(e) {
      // Get the current mouse position
      x = e.clientX;
      y = e.clientY;

      // Calculate the dimension of element
      const styles = window.getComputedStyle(ele);
      w = parseInt(styles.width, 10);
      h = parseInt(styles.height, 10);

      // Attach the listeners to `document`
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function(e) {
      // How far the mouse has been moved
      const dx = e.clientX - x;
      const dy = e.clientY - y;

      // Adjust the dimension of element
      // ele.style.width = `${w + dx}px`;
      ele.style.height = `${h + dy}px`;
  };

  const mouseUpHandler = function() {
      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
  };

  // Query all resizers
  const resizer = document.getElementById('resizer');
  resizer.addEventListener('mousedown', mouseDownHandler);
  resizer.addEventListener('touchstart', touchStartHandler);

});

// ******************
// END Code for resizing 
// ******************


const runLocal = false
var testAddress = ""
const perplexityAddress = () => {
  if(testAddress != "")
  {
    return testAddress
  }
  
  if(runLocal == true)
  {
    return "http://localhost:5000/"
  }
  else
  {
    return "https://h16rlkfppj.execute-api.us-east-1.amazonaws.com/prod/"
  }
}

const imagesAddress = () => {
  if(testAddress != "")
  {
    return testAddress
  }

  if(runLocal == true)
  {
    return "http://localhost:5000/"
  }
  else
  {
    return "https://perplexity.inductorsoftware.com/"
  }
}

const defaultGame = () => {
  var pathArray = window.location.pathname.split('/');
  var defaultGame = 'esl';
  var segment = '';
  if(pathArray[pathArray.length - 1] === '')
  {
    segment = pathArray[pathArray.length - 2];
  }
  else
  {
    segment = pathArray[pathArray.length - 1];
  }
  if(segment.substring(0, 4) === 'game')
  {
    defaultGame = segment.substring(4, segment.length);
  }
  console.log(defaultGame);
  return defaultGame;
}

const recallMessage = (messageList, messageIndex) => {
  let index = -1
  for(let i = 0; i < messageList.length; i++)
  {
    if(messageList[i].me === true)
    {
      index += 1
      if(index === messageIndex) 
      {
        return messageList[i].body
      }
    }
  }

  return ""
}


const maxRecallIndex = (messageList) => {
  let index = -1
  for(let i = 0; i < messageList.length; i++)
  {
    if(messageList[i].me === true)
    {
      index += 1
    }
  }

  return index
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      imagesVisibility: []
    }
    this.userID = Math.random().toString(36).substring(2) + Date.now().toString(36)
  }

  componentDidMount = () => {
    this.input.focus()
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    let inputTag = this.input.getElementsByTagName("input")[0]
    if(inputTag.value.startsWith("address:")) {
      testAddress = inputTag.value.trim().slice(8)
      if(testAddress.slice(-1) != "/")
      {
        testAddress = testAddress + "/"
      }
      
      inputTag.value = ""
    }
    else {
      this.handleNewMessage(inputTag.value)
      inputTag.value = ""
    }
  }

  // ******************
  // Handle arrow key presses
  // ******************
  handleArrowKeys = (event) => {
    let inputTag = document.getElementById("inputBox")

    if (this.state.messages.length === 0) {
      return
    }

    if (event.keyCode === 38) {
      // Up arrow
      event.preventDefault()
      let newRecallIndex = this.state.currentRecallIndex - 1
      if(newRecallIndex < 0) {
        newRecallIndex = 0
      }
      this.setState({currentRecallIndex: newRecallIndex})
      inputTag.value = recallMessage(this.state.messages, newRecallIndex)
    } else if (event.keyCode === 40) {
      // Down arrow
      event.preventDefault()
      let newRecallIndex = this.state.currentRecallIndex + 1
      if(newRecallIndex > maxRecallIndex(this.state.messages) + 1) {
        newRecallIndex = maxRecallIndex(this.state.messages) + 1
      }
      this.setState({currentRecallIndex: newRecallIndex})
      inputTag.value = recallMessage(this.state.messages, newRecallIndex)
    }
  }

  // returns mainString, images
  // where mainString is the string to display stripped of images
  // and images is an array of arrays: [[0, "kidneykwest/SimpleNLG.png"], [1, "kidneykwest/image2.png"]]
  // Format is ::0->kidneykwest/SimpleNLG.png:: for non-overlapping
  // or ::0->kidneykwest/SimpleNLG.png->overlap:: for overlapping
  getImages(gameString) {
    let parts = gameString.split("::")
    let mainString = ""
    let images = []
    if(parts.length > 1) {
      let i = 0
      while(i < parts.length)
      {
        mainString += parts[i]
        i += 1
        if(i < parts.length)
        {
          let image = parts[i]
          let imageSpec = image.split("->")
          if(imageSpec.length == 2)
          {
            images.push([imageSpec[0], imageSpec[1]])
          }
          else if(imageSpec.length == 3)
          {
            images.push([imageSpec[0], imageSpec[1], imageSpec[2]])
          }
          i += 1
        }
      }
    }
    else
    {
      mainString = gameString
    }

    return {
      mainString,
      images
    }
  }

  // Make the right sections of the HTMl visible depending on what images
  // Have been asked to be shown
  // And record what should be shown
  showImages(imageArray, component) {
    // reset the images to hidden
    let newImagesVisibility = ["", ""]

    // show any that are requested
    // last one for a position wins unless it is overlapping
    for(var index = 0; index < imageArray.length; index++) {
      let imageIndex = imageArray[index][0]
      let imageValue = imageArray[index][1]
      let imageOverlap = false
      if(imageArray[index].length == 3 && imageArray[index][2] == "overlap")
      {
        imageOverlap = true
      }

      // Now that we've parsed the image, figure out what to do with it
      if(newImagesVisibility[imageIndex] == "")
      {
        // Nothing shown yet in this position
        // the first image found sets whether this is overlapping or not
        newImagesVisibility[imageIndex] = [imageOverlap, [imageValue]]
      }
      else
      {
        if(newImagesVisibility[imageIndex][0] === true)
        {
          // This position has been set to overlapping
          // Only allow other overlapping images to be added
          if(imageOverlap === true)
          {
            // Add another overlapping image
            newImagesVisibility[imageIndex][1].push(imageValue)
          }
        }
        else
        {
          // This position has been set to nonoverlapping
          if(imageOverlap === false)
          {
            // Replace previous nonoverlapping with this one
            newImagesVisibility[imageIndex][1] = [imageValue]
          }
        }
      }
    }

    // Set the state of the component to the newImagesVisiblity
    component.setState({
      imagesVisibility: newImagesVisibility
    })
  }

  handleNewMessage = (text) => {
    fetch(perplexityAddress() + '?Game=' + defaultGame() + '&From=' + this.userID + '&StripImages=False&ReplyTo=&Service=aws&Body=' + text)
        .then(response => {
          this.setState({disableSend: false});
          if (!response.ok) {
            throw new Error('The Perplexity server is not responding...');
          }
          return response.text()
        })
        .then(data => {
            this.setState({disableSend: false});
            let formatted = data.indexOf("Phrase:") !== -1;
            let dataLines = data.trim().split("\n");
            let allImages = []
            for(var index = 0; index < dataLines.length; index++) {
              let parsed = this.getImages(dataLines[index])
              allImages = allImages.concat(parsed.images)
              this.setState({
                messages: [...this.state.messages, { me: false, author: "", formatted:false, body: parsed.mainString}],
              })
            }
            this.showImages(allImages, this)
          })
        .catch(error => {
            this.setState({disableSend: false});
            this.setState({
              messages: [...this.state.messages, { me: false, author: "", formatted:false, body: "Couldn't send the message because of a network failure..."}],
            })
        });
    
    this.setState({disableSend: true});
    this.setState({currentRecallIndex: maxRecallIndex(this.state.messages) + 2});

    this.setState({
      messages: [...this.state.messages, { me: true, author: "?", formatted:false, body: text }],
    })
  }


  render() {  
    let imagesRender = []
    for(let index = 0; index < this.state.imagesVisibility.length; index++)
    {
      imagesRender.push([])
      if(this.state.imagesVisibility[index] != "")
      {
        // There is an image(s) in this position
        let imageList = this.state.imagesVisibility[index][1]
        for(let imageIndex = 0; imageIndex < imageList.length; imageIndex++)
        {
          imagesRender[index].push(<img className= {classes.scaleImage} src={imagesAddress() + imageList[imageIndex]} alt="pic" />)
        }
      }
    }

    return (
      <div style = {{height:"100vh"}}>
        <meta name="description" content="App Description" />
        <Grid container className={classes.grid}>
          
          <Grid item xs={9}className={classes.grid}>
            <Grid container item  direction="column" className={classes.grid}>
  
              <Grid item id='resizeMe' className={classes.gridScrollableText}>                
                <div className={classes.scrollableText}>
                  {this.state.messages.length > 0 &&
                    <MessageList messages={this.state.messages} /> }
                  {this.state.messages.length == 0 &&
                    <span><p><b>Welcome to the <a href="https://blog.inductorsoftware.com/blog/PerplexityOverview">Perplexity natural language engine!</a></b></p><p>On mobile devices always use the "enter/return" key instead of the 'send' button to stop the page from moving around. You can drag the divider to get more text on the screen too!</p><p>Type "hello" and hit enter to get started!</p></span>}

                </div>
                <div id='resizer' className={classes.resizer}>      
                  
                  <img id='dragIcon' className={classes.resizeImage} src="GrabHandle.png"></img>
                </div>
              </Grid>
  
              <Grid item >
                
                <form className="MessageForm" onSubmit={this.handleFormSubmit}>
                  <Box className={classes.form} display="flex" p={1}>
                    <OutlinedInput id='inputBox' onKeyDown={this.handleArrowKeys} className={classes.textEntry} ref={(node) => (this.input = node)} onFocus={(e) => {
                    e.preventDefault(); e.stopPropagation();
                    document.body.scrollTop = 0;
                    window.scroll(0,0);
                    window.scrollTo(0,0);
                  }}/>
                  
                    <Button className={classes.sendButton} disabled={this.state.disableSend} type="submit" variant="contained" color="primary">
                        Send
                    </Button> 
                  </Box>
                </form>

              </Grid>
  
            </Grid>
  
          </Grid>
  
          <Grid item xs={3}>
            <Grid container direction="column" className={classes.columnRight}>
              <Grid item xs={2} className={classes.grid1}>
                <div className={classes.overlapImageDiv}>
                  {imagesRender[0]}
                </div>
              </Grid>
              <Grid item xs={2} className={classes.grid1}>
                <div className={classes.overlapImageDiv}>
                  {imagesRender[1]}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
 

export default App

